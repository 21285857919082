import React from 'react'
import { Authenticator } from '../components/Authenticator'
import { GatsbyPageContext } from '../context/GatsbyPageContext'
import ContentPageLayout from './content-page'
import { CookiePermissionProvider } from '../context/CookiePermission'
import { DistinctIdProvider } from '../context/DistinctId'
import Analytics from '../components/Analytics'
import '@deque/cauldron-react/lib/cauldron.css'
import CookieNotice from '../components/CookieNotice'
import { FeedbackModalProvider } from '../context/FeedbackModal'

declare const DISABLE_AUTH: boolean

interface LayoutProps {
  children: React.ReactElement
  pageContext: GatsbyPageContextObject
}

/**
 * Wraps the page in a component that will not be unmounted
 * when routes change (for example, when clicking a Gatsby
 * Link). Allows component state to survive route changes.
 *
 * Implemented by `gatsby-plugin-layout`
 */

export default function Layout(props: LayoutProps) {
  let pageContent: React.ReactNode = props.children

  if (props.pageContext.layout === 'cors-resource') {
    return <>{pageContent}</>
  }

  if (props.pageContext.layout === 'content-page') {
    pageContent = (
      <GatsbyPageContext.Provider value={props.pageContext}>
        <Authenticator disable={DISABLE_AUTH}>
          <FeedbackModalProvider>
            <ContentPageLayout>{props.children}</ContentPageLayout>
          </FeedbackModalProvider>
        </Authenticator>
      </GatsbyPageContext.Provider>
    )
  }

  return (
    <CookiePermissionProvider>
      <DistinctIdProvider>
        <Analytics>
          <CookieNotice />
          {pageContent}
        </Analytics>
      </DistinctIdProvider>
    </CookiePermissionProvider>
  )
}
