import React from 'react'

type Context = { state: State; dispatch: Dispatch }
type State = boolean
type Dispatch = (action: Action) => void
type Action = { type: 'update'; value: boolean }
type ProviderProps = { children: React.ReactNode }

const FeedbackModalContext = React.createContext<Context | undefined>(undefined)

function reducer(_state: State, action: Action): State {
  switch (action.type) {
    case 'update':
      return action.value
    default:
      throw new Error(`unhandled action type: ${action.type}`)
  }
}

function FeedbackModalProvider(props: ProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, false)
  return (
    <FeedbackModalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FeedbackModalContext.Provider>
  )
}

function useFeedbackModal(): Context {
  const context = React.useContext(FeedbackModalContext)
  if (context === undefined) {
    throw new Error(
      'useFeedbackModal must be used within a FeedbackModalProvider'
    )
  }
  return context
}

export { FeedbackModalProvider, useFeedbackModal }
